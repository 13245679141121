import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Task } from 'src/app/models/task/Task';
import { DataService } from 'src/app/services/data/data.service';
// eslint-disable-next-line max-len
import { syncTasks, syncTasksSuccess, syncTasksFail, loadTasks, syncTask, syncTaskSuccess, upsertTask, syncTaskFail, syncProjectTasks, syncProjectTasksFail, syncProjectTasksSuccess, upsertTasks } from './tasks.actions';

@Injectable()
export class TasksEffects {

  syncTasks$ = createEffect(() => this.actions$.pipe(
    ofType(syncTasks),
    switchMap(() => this.dataService.getTasks().pipe(
      map((tasks) => syncTasksSuccess({ tasks })),
      catchError(error => of(syncTasksFail({ error })))
    ))
  ));

  syncTasksSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(syncTasksSuccess),
    map((payload: { tasks: Task[] }) => loadTasks(payload))
  ));

  syncTask$ = createEffect(() => this.actions$.pipe(
    ofType(syncTask),
    switchMap((payload: { id: string; data: any }) => this.dataService.updateTask(payload.id, payload.data).pipe(
      map((task) => syncTaskSuccess({ task })),
      catchError(error => of(syncTaskFail({ error })))
    ))
  ));

  syncTaskSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(syncTaskSuccess),
    map((payload: { task: Task }) => upsertTask(payload))
  ));


  syncProjectTasks$ = createEffect(() => this.actions$.pipe(
    ofType(syncProjectTasks),
    switchMap((payload: { projectId: string }) => this.dataService.getProjectTasks(payload.projectId).pipe(
      map((tasks) => syncProjectTasksSuccess({ tasks })),
      catchError(error => of(syncProjectTasksFail({ error })))
    ))
  ));

  syncProjectTasksSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(syncProjectTasksSuccess),
    map((payload: { tasks: Task[] }) => upsertTasks(payload))
  ));

  constructor(
    private actions$: Actions,
    private dataService: DataService
  ) { }
}
