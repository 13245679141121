import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ModalDrawComponent } from './modal-draw.component';
import { FormsModule } from '@angular/forms';
import { CanvasModule } from '../canvas/canvas.module';
import { CanvasComponent } from '../canvas/canvas.component';
import { ButtonModule } from '../button/button.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    FormsModule,
    CanvasModule,
    ButtonModule
  ],
  exports: [
    ModalDrawComponent
  ],
  declarations: [
    ModalDrawComponent
  ],
  entryComponents: [
    CanvasComponent
  ]
})
export class ModalDrawModule { }
