import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { appStoreModule } from 'src/app/states/AppStoreModule';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoadingComponent } from './components/loading/loading.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MomentModule } from 'ngx-moment';
import { ModalDrawComponent } from './components/modal-draw/modal-draw.component';
import { ModalDrawModule } from './components/modal-draw/modal-draw.module';
import { ModalFormModule } from './components/modal-form/modal-form.module';
import { ModalFormComponent } from './components/modal-form/modal-form.component';
import { ModalStatusComponent } from './components/modal-status/modal-status.component';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { ModalStatusModule } from './components/modal-status/modal-status.module';
import { ModalConfirmModule } from './components/modal-confirm/modal-confirm.module';
import { ModalInterventTypeModule } from './components/modal-intervent-type/modal-intervent-type.module';
import { ModalInterventTypeComponent } from './components/modal-intervent-type/modal-intervent-type.component';
import { ModalSyncComponent } from './components/modal-sync/modal-sync.component';
import { ModalSyncModule } from './components/modal-sync/modal-sync.module';
import { ModalZoomComponent } from './components/modal-zoom/modal-zoom.component';
import { ModalZoomModule } from './components/modal-zoom/modal-zoom.module';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

/**
 * The root module
 */
@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent
  ],
  entryComponents: [
    ModalFormComponent,
    ModalDrawComponent,
    ModalStatusComponent,
    ModalConfirmComponent,
    ModalInterventTypeComponent,
    ModalSyncComponent,
    ModalZoomComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    ...appStoreModule,
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicStorageModule.forRoot(),
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59
      }
    }),
    ModalFormModule,
    ModalDrawModule,
    ModalStatusModule,
    ModalConfirmModule,
    ModalInterventTypeModule,
    ModalSyncModule,
    ModalZoomModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }
