import { Action, createReducer, on } from '@ngrx/store';
import { appInitialState } from '../AppInitialState';
// eslint-disable-next-line max-len
import { clearSyncImages, clearSyncSections, imagesToSync, sectionsToSync, startSyncImages, startSyncSections, stopSyncImages, stopSyncSections } from './syncing.actions';
import { SyncingState } from './SyncingState';

/**
 * The initial state for the syncing
 */
const initialState: SyncingState = appInitialState.syncing;

/**
 * The reducer for the syncing state
 */
const reducer = createReducer(
  initialState,
  on(imagesToSync, (currentState) => ({
    ...currentState,
    imagesToSync: true
  })),
  on(startSyncImages, (currentState) => ({
    ...currentState,
    imagesToSync: false,
    imagesSyncing: true,
    imagesSynced: false
  })),
  on(stopSyncImages, (currentState) => ({
    ...currentState,
    imagesSyncing: false,
    imagesSynced: true
  })),
  on(clearSyncImages, (currentState) => ({
    ...currentState,
    imagesToSync: false,
    imagesSyncing: false,
    imagesSynced: false
  })),
  on(sectionsToSync, (currentState) => ({
    ...currentState,
    sectionsToSync: true
  })),
  on(startSyncSections, (currentState) => ({
    ...currentState,
    sectionsToSync: false,
    sectionsSyncing: true,
    sectionsSynced: false
  })),
  on(stopSyncSections, (currentState) => ({
    ...currentState,
    sectionsSyncing: false,
    sectionsSynced: true
  })),
  on(clearSyncSections, (currentState) => ({
    ...currentState,
    sectionsToSync: false,
    sectionsSyncing: false,
    sectionsSynced: false
  }))
);

/**
 * The syncing reducer
 */
export const syncingReducer = (state: SyncingState, action: Action) => reducer(state, action);
