import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ModalZoomComponent } from './modal-zoom.component';
import { SwiperModule } from 'swiper/angular';
import { ButtonComponent } from '../button/button.component';
import { ButtonModule } from '../button/button.module';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    SwiperModule,
    ButtonModule
  ],
  exports: [
    ModalZoomComponent
  ],
  declarations: [
    ModalZoomComponent
  ],
  entryComponents: [
    ButtonComponent
  ]
})
export class ModalZoomModule { }
