import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { Utilities } from 'src/app/commons/utilities.class';
import { Task } from 'src/app/models/task/Task';
import { DataService } from 'src/app/services/data/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-sync',
  templateUrl: './modal-sync.component.html',
  styleUrls: ['./modal-sync.component.scss'],
})
export class ModalSyncComponent implements OnInit {
  items: any[];
  synced: number;
  total: number;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private dataService: DataService
  ) {
    this.items = this.navParams.get('items');
  }

  ngOnInit() {
    // console.log(this.items);
    this.synced = 0;
    this.total = this.items.length;

    this.sendItems();
  }

  async dismiss(data: any) {
    // console.log('dismiss', data);
    await this.modalController.dismiss({
      ...data,
      dismissed: true
    });
  }

  cancel() {
    this.dismiss({
      confirmed: false
    });
  }

  private async sendItems() {
    const ids = [];
    await Utilities.asyncForEach(this.items, async (item: Task) => {
      await this.sendItem(item);
      ids.push(item.id);
      this.synced++;
    });

    const data = {
      confirmed: true,
      ids
    };
    this.dismiss(data);

  }

  private async sendItem(item: Task) {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log('item', item);
        const preparedItem = {
          ...item,
          status: 'submitted'
        };
        await Utilities.waitFor(1000);
        this.dataService.addTask(preparedItem).pipe(take(1)).subscribe((task) => {
          // console.log('task', task);
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}
