import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { NetworkService } from './services/network/network.service';
import { LanguageService } from './services/language/language.service';
import { Platform } from '@ionic/angular';

/**
 * The root component
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  /**
   * The constructor
   *
   * @param swUpdate
   */
  constructor(
    // private swUpdate: SwUpdate,
    private translateService: TranslateService,
    private platform: Platform,
    private languageService: LanguageService,
    private networkService: NetworkService

  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translateService.use('en');
  }

  /**
   * The lifecycle hook that is called after Angular has initialized all data-bound properties of the APP directive.
   */
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() { }

  /**
   * The lifecycle hook that is called after Angular has fully initialized the APP component's view.
   */
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit() {
    // if (this.swUpdate.isEnabled) {
    //   this.swUpdate.available
    //     .subscribe(() => {
    //       this.swUpdate
    //         .activateUpdate()
    //         .then(() => {
    //           window.location.reload();
    //         });
    //     });
    // }
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.languageService.setInitialAppLanguage();
    });
  }

}
