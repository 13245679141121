import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';

const LNG_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selected = '';

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService
  ) { }

  setInitialAppLanguage() {
    let language = this.translateService.getBrowserLang();
    language = (this.isAvailableLanguage(language)) ? language : 'en';
    this.translateService.setDefaultLang(language);

    this.storageService.get(LNG_KEY).then(val => {
      if (val) {
        this.setLanguage(val);
        this.selected = val;
      }
      else {
        this.setLanguage(language);
        this.selected = language;
      }
    });
  }

  isAvailableLanguage(lng) {
    let isAvailableLanguage = false;
    switch (lng) {
      case 'en':
      case 'it':
        isAvailableLanguage = true;
        break;

      default:
        isAvailableLanguage = false;
    }
    return isAvailableLanguage;
  }

  getLanguages() {
    return [
      { text: 'Italiano', value: 'it', img: 'assets/imgs/it.png' },
    ];
  }

  setLanguage(lng) {
    this.translateService.use(lng);
    this.selected = lng;
    this.storageService.set(LNG_KEY, lng);
  }

  async getLanguage() {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const lng = await this.storageService.get(LNG_KEY);
        resolve(lng);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getTranslation(sourceString, params = null) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        this.translateService.get(sourceString, params).subscribe(
          outputString => {
            resolve(outputString);
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  }
}
