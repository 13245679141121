import { Injectable } from '@angular/core';
import { MomentModule } from 'ngx-moment';

@Injectable()
export class Utilities {

  static getParameterByName(name: string, url: string) {
    if (!url) { url = window.location.href; }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) { return null; }
    if (!results[2]) { return ''; }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  static async waitFor(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  static async asyncForEach(source, callback) {
    if (source instanceof Array) {
      for (let index = 0; index < source.length; index++) {
        await callback(source[index], index, source);
      }
    }
    else {
      for (const key in source) {
        if (source.hasOwnProperty(key)) {
          await callback(source[key], key, source);
        }
      }
    }
  }

  /**
   * Return the task marker icon.
   *
   * @param type
   * @param status
   * @param isOpen
   * @returns
   */
  static getTaskMarkerIcon(type: string, status: string, isOpen: boolean = true) {
    const iconUrl = this.getTaskIconPath(type, status, isOpen);
    const shadowUrl = this.getTaskIconShadowPath(type, status, isOpen);
    const iconSize = this.getTaskIconSize(type, status, isOpen);
    const shadowSize = this.getTaskIconShadowSize(type, status, isOpen);
    const iconAnchor = this.getTaskIconAnchor(type, status, isOpen);
    const shadowAnchor = this.getTaskIconShadowAnchor(type, status, isOpen);
    const popupAnchor = this.getTaskIconPopoupAnchor(type, status, isOpen);
    const markerIcon = {
      iconUrl,
      shadowUrl,
      iconSize,
      shadowSize,
      iconAnchor,
      shadowAnchor,
      popupAnchor,
    };
    return markerIcon;
  }

  /**
   * Return task icon path.
   *
   * @param status
   * @param isOpen
   * @returns
   */
  static getTaskTitle(status: string, isOpen: boolean = true) {
    if (!status) {
      return status;
    }
    return '' + status[0].toUpperCase() + status.substring(1).toLowerCase();
  }

  /**
   * Return task icon path.
   *
   * @param type
   * @param status
   * @param isOpen
   * @returns
   */
  static getTaskIconPath(type: string, status: string, isOpen: boolean = true) {
    if (!type || !status) {
      return '';
    }

    return '/assets/img/task/status/' +
      ((!isOpen) ? 'closed/' : '') +
      status.trim().replace(' ', '_') + '/' +
      type.trim().replace(' ', '_') + '.svg';
  }

  /**
   * Return task icon shadow path.
   *
   * @param type
   * @param status
   * @param isOpen
   * @returns
   */
  static getTaskIconShadowPath(type: string, status: string, isOpen: boolean = true) {
    return '';
  }

  /**
   * Return task icon Size.
   *
   * @param type
   * @param status
   * @param isOpen
   * @returns
   */
  static getTaskIconSize(type: string, status: string, isOpen: boolean = true) {
    // TODO
    return [30, 43];
  }

  /**
   * Return task icon shadow Size.
   *
   * @param type
   * @param status
   * @param isOpen
   * @returns
   */
  static getTaskIconShadowSize(type: string, status: string, isOpen: boolean = true) {
    return [0, 0];
  }

  /**
   * Return the point of the icon which will correspond to marker's location.
   *
   * @param type
   * @param status
   * @param isOpen
   * @returns
   */
  static getTaskIconAnchor(type: string, status: string, isOpen: boolean = true) {
    // TODO
    return [14, 43];
  }

  /**
   * Return the point of the shadow which will correspond to marker's location.
   *
   * @param type
   * @param status
   * @param isOpen
   * @returns
   */
  static getTaskIconShadowAnchor(type: string, status: string, isOpen: boolean = true) {
    return [0, 0];
  }

  /**
   * Return the point from which the popup should open relative to the iconAnchor.
   *
   * @param type
   * @param status
   * @param isOpen
   * @returns
   */
  static getTaskIconPopoupAnchor(type: string, status: string, isOpen: boolean = true) {
    return [0, 0];
  }

  static getInitials(fullName: string, minChars: number = null, maxChars: number = null) {
    let initials = '';
    if (fullName) {
      const fullNameSplitted = fullName.split(' ');
      minChars = (!minChars) ? 0 : minChars;
      maxChars = (!maxChars) ? fullNameSplitted.length : maxChars;
      fullNameSplitted.forEach((element, index) => {
        if (index < maxChars) {
          initials += element.charAt(0).toLocaleUpperCase();
        }
      });

      initials += '#'.repeat((initials.length < minChars) ? (minChars - initials.length) : 0);
    }
    return initials;
  }

  static getDateToFromNowDaily(customDate) {
    // if (moment(customDate).isValid()) {
    //   // get from-now for this date
    //   var fromNow = moment(customDate).fromNow();

    //   // ensure the date is displayed with today and yesterday
    //   return moment(customDate).calendar(null, {
    //     // when the date is closer, specify custom values
    //     lastWeek: '[Last] dddd [at] HH:mm',
    //     lastDay: '[Yesterday] [at] HH:mm',
    //     sameDay: '[Today] [at] HH:mm',
    //     nextDay: '[Tomorrow] [at] HH:mm',
    //     nextWeek: 'dddd [at] HH:mm',
    //     // when the date is further away, use from-now functionality
    //     sameElse: function () {
    //       return "[" + fromNow + "]";
    //     }
    //   });
    // }
    // // this.task.lastupdate_text = moment(this.task['updated-at']).format('DD.MM.YYYY HH:mm');
    return ' ';
  }
}
