import { Action, createReducer, on } from '@ngrx/store';
import { appInitialState } from '../AppInitialState';
import { connectionChange } from './network.actions';
import { NetworkState } from './NetworkState';

/**
 * The initial state for the network
 */
const initialState: NetworkState = appInitialState.network;

/**
 * The reducer for the network state
 */
const reducer = createReducer(
  initialState,
  on(connectionChange, (currentState, action) => ({
    ...currentState,
    connected: ((('wifi' === action.connectionType) || ('cellular' === action.connectionType)) ? true : false),
    connectionType: action.connectionType
  }))
);

/**
 * The network reducer
 */
export const networkReducer = (state: NetworkState, action: Action) => reducer(state, action);
