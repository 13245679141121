import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Boat } from 'src/app/models/boat/Boat';
import { Project } from 'src/app/models/project/Project';
import { Section } from 'src/app/models/section/Section';
import { ErrorHandlerService } from '../jsonapi/error-handler.service';
import { JsonApiService } from '../jsonapi/jsonapi.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    private errorHandler: ErrorHandlerService,
    private api: JsonApiService
  ) { }

  async getBoats() {
    return new Observable<Project[]>(observer => {
      this.api.getCollection('boats').then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const boats = (response.data) ? response.data : [];
          // console.log('boats', boats);
          const projects = [];
          observer.next(projects);
        }, (error) => {
          observer.error(error);
        });
      });
    });
  }

  async getProjects() {
    return new Observable<Project[]>(observer => {
      this.api.getCollection('projects').then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const projects = (response.data) ? response.data : [];
          observer.next(projects);
        }, (error) => {
          observer.error(error);
        });
      });
    });
  }

  async getProjectBoat(projectId: string) {
    return new Observable<Boat>(observer => {
      this.api.getRelatedCollection('projects', projectId, 'boat').then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const boat = (response.data) ? response.data : [];
          observer.next(boat);
        });
      });
    });
  }

  async getProjectSections(boatId: string) {
    return new Observable<Section[]>(observer => {
      this.api.getRelatedCollection('boats', boatId, 'sections').then((call$) => {
        call$.pipe(take(1)).subscribe((response: any) => {
          const projects = (response.data) ? response.data : [];
          observer.next(projects);
        });
      });
    });
  }
}
