import { createAction } from '@ngrx/store';

/**
 * The images to sync action
 */
export const imagesToSync = createAction('[Syncing images] add');

/**
 * The start sync action
 */
export const startSyncImages = createAction('[Syncing images] start');

/**
 * The stop sync action
 */
export const stopSyncImages = createAction('[Syncing images] stop');

/**
 * The clear sync action
 */
export const clearSyncImages = createAction('[Syncing images] clear');

/**
 * The sections to sync action
 */
export const sectionsToSync = createAction('[Syncing sections] add');

/**
 * The start sync action
 */
export const startSyncSections = createAction('[Syncing sections] start');

/**
 * The stop sync action
 */
export const stopSyncSections = createAction('[Syncing sections] stop');

/**
 * The clear sync action
 */
export const clearSyncSections = createAction('[Syncing sections] clear');
