import { Token } from '@angular/compiler/src/ml_parser/lexer';
import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/models/user/User';

/**
 * The recover password action
 */
export const recoverPassword = createAction('[Recover password]', props<{ email: string }>());

/**
 * The recover password success action
 */
export const recoverPasswordSuccess = createAction('[Recover password] success');

/**
 * The recover password fail action
 */
export const recoverPasswordFail = createAction('[Recover password] fail', props<{ error: any }>());

/**
 * The login action
 */
export const login = createAction('[Login]', props<{ email: string; password: string; instance: string }>());

/**
 * The login success action
 */
export const loginSuccess = createAction('[Login] success', props<{ user: User }>());

/**
 * The login fail action
 */
export const loginFail = createAction('[Login] fail', props<{ error: any }>());

/**
 * The update user success action
 */
export const updateUserSuccess = createAction('[UpdateUser] success', props<{ user: any }>());

/**
 * The update user fail action
 */
export const updateUserFail = createAction('[updateUser] fail', props<{ error: any }>());

/**
 * The refresh token action
 */
export const refreshToken = createAction('[RefreshToken]', props<{ refreshToken: string }>());

/**
 * The refresh token success action
 */
export const refreshTokenSuccess = createAction('[RefreshToken] success', props<{ user: User }>());

/**
 * The refresh token fail action
 */
export const refreshTokenFail = createAction('[RefreshToken] fail', props<{ error: any }>());

/**
 * The logout action
 */
export const logout = createAction('[Logout]');

/**
 * The logout success action
 */
export const logoutSuccess = createAction('[Logout] success');

/**
 * The logout fail action
 */
export const logoutFail = createAction('[Logout] fail', props<{ error: any }>());
